import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.entityWatching?.metaEntity?.id)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "javascript:void(0);",
          class: "menu-link px-3",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.watchEntity && _ctx.watchEntity(...args)))
        }, " Watch "))
      : _createCommentVNode("", true),
    (_ctx.entityWatching?.metaEntity?.id)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: "javascript:void(0);",
          class: "menu-link px-3",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.unWatch(_ctx.entityWatching?.metaEntity?.id)))
        }, " UnWatch "))
      : _createCommentVNode("", true)
  ], 64))
}

import {computed, defineComponent, ref} from "vue";
import {useStore} from "vuex";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {LoadBaseModal, LoadEntity, LoadPanel, LoadTagModal} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import {IPolicy} from "@/core/entity/IPolicy";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import {onUpdated} from "@vue/runtime-core";
import EntityView from "@/components/base/common/EntityView.vue";
import PolicyStatus from "@/views/policy/PolicyStatus.vue";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import MenuWatch from "@/views/metaEntity/MenuWatch.vue";
import WatchAlert from "@/views/metaEntity/WatchAlert.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import FormNote from "@/views/activity/FormNote.vue";
import Warnings from "@/views/note/Warnings.vue";
import Swal from "sweetalert2";
import PolicyRemoteSearchService from "@/core/services/PolicyRemoteSearchService";
import {useToast} from "vue-toast-notification";
import TagEntities from "@/views/tag/TagEntities.vue";

export default defineComponent({
  name: "Policy",
  components: {
    TagEntities,
    Warnings,
    FormNote,
    BaseModal,
    EntityLoading,
    WatchAlert,
    MenuWatch,
    DateTimeFormat,
    TabNavigate,
    PolicyStatus,
    EntityView,
    QuickAction
  },
  setup() {
    const noteModel = ref({text: '', policyId: ''});
    const store = useStore();
    const policy = computed<IPolicy>(() => store.state.PolicyModule.policy)
    const entityLoading = computed(() => store.state.LoadingModule.entityLoading);
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Policy Details', [
        {link: false, router: '', text: 'View Policy'}
      ])
    })
    const editingCase = computed(() => store.state.CaseModule.editingCase)
    return {
      ...LoadEntity(Actions.LOAD_POLICY),
      policy,
      store,
      editingCase,
      entityLoading,
      ...LoadPanel(),
      noteModel,
      ...LoadBaseModal(),
      ...LoadTagModal(),
    }
  },
  methods: {
    onAddCase() {
      this.store.dispatch(Actions.EDIT_CASE, {})
    },
    onSavePolicy() {
      this.store.dispatch(Actions.LOAD_POLICY_CASES, this.id)
      const baseModal = this.$refs.caseForm as typeof BaseModal
      baseModal.hideBaseModal()
    },
    addWarning() {
      this.noteModel.text = ''
      this.noteModel.policyId = this.policy.id
      const baseModal = this.$refs.noteForm as typeof BaseModal
      baseModal.showBaseModal()
    },
    onSaveWarning() {
      const baseModal = this.$refs.noteForm as typeof BaseModal
      baseModal.hideBaseModal();
      const warning = this.$refs.warningsRef as typeof Warnings
      warning.paginationLoad();
    },
    onRefresh() {
      Swal.showLoading();
      PolicyRemoteSearchService.update(this.policy.id, {}).then(() => {
        this.reloadData();
        useToast().success('Refresh successfully', {position: 'top-right'})
      }).finally(() => {
        Swal.close();
      })

    }

  }
})


import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "PolicyStatus",
  props: {
    status: {required: true, type: String}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const build = (status) => {
      switch (status) {
        case 'ACTIVE':
          data.value = {name: 'Confirmed', clazz: 'success'}
          break
        case 'INFORCE':
          data.value = {name: 'In-force', clazz: 'success'}
          break
        case 'TEMPORARY':
          data.value = {name: 'Temporary', clazz: 'primary'}
          break
        case 'NOT_CONFIRMED':
          data.value = {name: 'Not-Confirmed', clazz: 'danger'}
          break
        case 'CANCELED':
          data.value = {name: 'Canceled', clazz: 'warning'}
          break
        case 'TERMINATED':
          data.value = {name: 'Terminated', clazz: 'danger'}
          break
      }
    }
    build(props.status)
    watch(() => props.status, (cb) => {
      build(cb)
    })
    return {
      data
    }
  }
})

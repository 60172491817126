
import { computed, defineComponent, ref } from "vue";
import store from "@/store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2";
import NoteService from "@/core/services/NoteService";

export default defineComponent({
  name: "Warnings",
  components: {Avatar, DateTimeFormat, QuickAction},
  props: {
    object: {type: Object, default: () => ({type: '', id: ''})}
  },
  setup(props) {
    const objectRef = ref(props.object);
    const filterObject = ref({claimId: '', cazeId: '', policyId:''})
    switch (objectRef.value.type) {
      case 'CASE':
        filterObject.value.cazeId = objectRef.value.id
        break
      case 'CLAIM':
        filterObject.value.claimId = objectRef.value.id
        break
      case 'POLICY':
        filterObject.value.policyId = objectRef.value.id
        break
    }
    const warningPage = computed(() => store.state.NoteModule.warningPage)
    return {
      ...LoadFilterObjects(Actions.LOAD_WARNING_NOTES, filterObject.value, ['user']),
      warningPage,
      filterObject,
    }
  }
  ,
  watch: {
    object(cb) {
      switch (cb.type) {
        case 'CASE':
          this.filterObject.cazeId  = cb.id
          break
        case 'CLAIM':
          this.filterObject.claimId = cb.id
          break
        case 'POLICY':
          this.filterObject.policyId = cb.id
          break
      }
      this.updateFilterObject(this.filterObject)
    }
  },
  methods: {
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await NoteService.delete(id).then(() => {
            this.paginationLoad()
          })
        }
      })
    }
  }

})

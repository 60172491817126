
import {defineComponent, ref, watch} from "vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "Avatar",
  props: {
    avatar: {type: String},
    userId: {type: String},
  },
  setup(props) {
    const src = ref(props.avatar !== null && props.avatar != undefined && props.avatar !== '' ? props.avatar : '/media/avatars/blank.png')
    watch(() => props.avatar, cb => {
      src.value = cb !== null && cb != undefined && cb !== '' ? cb : '/media/avatars/blank.png'
    })
    return {
      src,
      ...LoadPanel(),
    }
  }
})


import { computed, defineComponent, watch } from "vue";
import store from "@/store";
import EntityMetaService from "@/core/services/EntityMetaService";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toast-notification";

export default defineComponent({
  name: "MenuWatch",
  props: {
    id: {type: String},
    objectType: {type: String},
  },
  setup(props) {
    const user = computed(() => store.getters.currentUser);
    const entityWatching = computed(() => store.state.EntityMetaModule.entityWatching)
    const load = (objectId, objectType) => {
      store.dispatch(Actions.LOAD_WATCHING_ENTITY, {
        objectId: objectId,
        objectType: objectType,
        type: "WATCH"
      })
    }
    watch(() =>props.id,  (cb)=>{
      load(cb, props.objectType);
    })
    watch(() =>props.objectType,  (cb)=>{
      load(props.id, cb);
    })
    load(props.id, props.objectType);
    const watchEntity = () => {
      const model = {
        objId1: user.value.id,
        objType1: 'USER',
        objId2: props.id,
        objType2: props.objectType,
        type: 'WATCH'
      }
      EntityMetaService.create(model).then(() => {
        store.dispatch(Actions.LOAD_WATCHING_ENTITY, {
          objectId: props.id,
          objectType: props.objectType,
          type: "WATCH"
        })
      })
    }
    const unWatch = (id) => {
      EntityMetaService.delete(id).then(() => {
        store.dispatch(Actions.LOAD_WATCHING_ENTITY, {
          objectId: props.id,
          objectType: props.objectType,
          type: "WATCH"
        })
        useToast().warning("UnWatch Successfully", {position: 'top-right'})
      })
    }

    return {
      unWatch,
      watchEntity,
      entityWatching
    }

  }
})

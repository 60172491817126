
import {defineComponent, ref, watch} from 'vue'
import TagEntityService from "@/core/services/TagEntityService";
import {TAG_COLOR} from "@/core/config/Constant";
import store from "@/store";
import {Mutations} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2";

export default defineComponent({
  name: "TagEntities",
  props: {
    entity: {type: Object, required: true}
  },
  setup(props) {
    const tags = ref<any>([]);
    const loadData = (entity) => {
      TagEntityService.getTags(entity.objectId, entity.objectType).then(res => {
        tags.value = res;
      })
    }
    loadData(props.entity);
    watch(() => props.entity, cb => {
      loadData(cb);
    })
    store.subscribe((mutation) => {
      if (mutation.type === Mutations.ADDED_TAGS) {
        loadData(props.entity);
      }
    });
    return {
      loadData,
      tags,
    }
  },

  methods: {
    buildColor(color) {
      const c = TAG_COLOR.find(item => item.name === color);
      return c ? c.hex : '';
    },
    onRemove(tag) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await TagEntityService.removeFromEntity(tag.id, this.entity.objectId, this.entity.objectType).then(() => {
            this.loadData(this.entity)
            store.commit(Mutations.ADDED_TAGS)
          })
        }
      })
    }
  }
})

import {BaseService} from "@/core/services/BaseService";

class PolicyRemoteSearchService extends BaseService<any> {
  endpoint = "/policy-remote-search";

  async getCovered(policyCode, certificate, coveredLifeId) {
    return this.http().get(this.endpoint + "/" + policyCode + "/" + certificate + "/" + coveredLifeId).then(res => {
      return res.data;
    })
  }

  async createPolicy(policyCode, certificate, coveredLifeId) {
    return this.http().post(this.endpoint + "/" + policyCode + "/" + certificate + "/" + coveredLifeId).then(res => {
      return res.data;
    })
  }

  async getInsured(id) {
    return this.http().get(this.endpoint + "/insured/" + id).then(res => {
      return res.data
    })
  }

  async createBSHIPolicy(data) {
    return this.http().post(this.endpoint + "/bhsi", data).then(res => {
      return res.data
    })
  }
}

export default new PolicyRemoteSearchService();
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    alt: "Pic",
    class: "cursor-pointer",
    src: _ctx.src,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadData(_ctx.userId, 'USER'))),
    style: {"margin-right":"10px"}
  }, null, 8, _hoisted_1))
}
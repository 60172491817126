
import { computed, defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  name: "WatchAlert",
  props: {
    title: {type: String, default: 'case'}
  },
  setup() {
    const entityWatching = computed(() => store.state.EntityMetaModule.entityWatching)
    return {
      entityWatching
    }
  }
})

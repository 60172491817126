import {BaseService} from "@/core/services/BaseService";

class TagEntityService extends BaseService<any> {
  endpoint = '/tag-entity';

  async addToEntity(tagId, objectId, objectType) {
    return this.http().post(this.endpoint + "/" + tagId + "/" + objectId + "/" + objectType).then(res => {
      return res.data;
    })
  }

  async removeFromEntity(tagId, objectId, objectType) {
    return this.http().delete(this.endpoint + "/" + tagId + "/" + objectId + "/" + objectType).then(res => {
      return res.data;
    })
  }

  async getTags(objectId, objectType) {
    return this.http().get(this.endpoint + "/" + objectId + "/" + objectType).then(res=>{
      return res.data;
    })
  }
}

export default new TagEntityService();

import { defineComponent, ref, watch } from "vue";
import { LoadPanel } from "@/core/composite/composite";

export default defineComponent({
  name: "EntityView",
  props: {
    clientCode: {type: String},
    clientId: {type: String}
  },
  setup(props) {
    const logo = ref(`/media/svg/client-logos/${props.clientCode}.png`);
    watch(() => props.clientCode, (cb) => {
      logo.value = `/media/svg/client-logos/${cb}.png`
    })
    const error =(e)=>{
      e.target.src =  '/media/logos/tr-logo.png'
    }
    return {
      error,
      logo,
      ...LoadPanel(),
    }
  }
})


import { Form } from 'vee-validate'
import { defineComponent, ref, watch } from "vue";
import Froala from "@/components/base/editor/Froala.vue";
import NoteService from "@/core/services/NoteService";

export default defineComponent({
  name: "FormNote",
  components: {Froala, Form},
  props: {
    note: {required: true, type: Object}
  },
  setup(props) {
    const noteProps = ref(props.note)
    watch(() => props.note, (cb) => {
      console.log(cb)
      noteProps.value = cb;
    })
    return {
      noteProps,
    }
  },
  methods: {
    onSubmit() {
      if (!this.note.id) {
        NoteService.create(this.noteProps).then(res => {
          this.$emit('onSaveNote', res)
        })
      }
    }
  },
  watch: {
    note(cb) {
      console.log(cb);
    }
  }
})
